<template>
  <v-card
    flat>
    <v-card-subtitle>Find Data</v-card-subtitle>
    <v-card-text class="px-5">
      <v-row>
        <!-- <v-text-field
          class="mr-2"
          dense
          v-model="monthInfo.month"
          label="MONTH"
          outlined />
        <v-text-field
          class="mr-2"
          dense
          v-model="monthInfo.year"
          label="YEAR"
          outlined /> -->
        
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="date"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              class="mr-5"
              v-model="date"
              label="Month"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"></v-text-field>
          </template>
          <v-date-picker
            v-model="date"
            type="month"
            no-title
            scrollable>
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="menu = false">
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.menu.save(date)">
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>

        <v-combobox
          class="mr-2"
          v-model="monthInfo.tel_type"
          :items="telType"
          item-text="keys"
          item-value="value"
          label="TEL TYPE"
          outlined
          dense></v-combobox>

        <v-btn
          color="primary"
          outlined
          @click="findData">
          <v-icon class="mr-2">mdi-clipboard-text-search</v-icon>
          SEARCH
        </v-btn>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'findCondition',
  props: {
    monthInfo: {
      type: Object
    }
  },

  data () {
    return {
      telType: [
        { keys: 'ALL', value: 'A' },
        { keys: 'Prepaid', value: 'P' },
        { keys: 'Postpaid', value: 'T' }
      ],

      date: new Date().toISOString().substr(0, 7),
      menu: false,
    }
  },

  created() {
    this.splitDateReport(this.date);
  },

  watch: {
    date (val) {
      this.splitDateReport(val);
    }
  },

  methods: {
    findData() {
      // eslint-disable-next-line no-undef
      eventBus.$emit('findData', this.monthInfo);
    },

    splitDateReport(val) {
      const d = val.split('-');
      this.monthInfo.month = d[1].toString();
      this.monthInfo.year = d[0].toString();
    }
  }
}
</script>